import React, { useEffect } from "react";
import AlertBanner from "../../containers/AlertBanner/AlertBanner";
import Banner from "../../containers/Banner/Banner";
import About from "../../containers/About/About";
import Customers from "../../containers/Customers/Customers";
import Solution from "../../containers/Solution/Solution";
import DemoCall from "../../containers/DemoCall/DemoCall";
import Statistics from "../../containers/Statistics/Statistics";
import "./HomePage.css";
import Features from "../../containers/Features/Features";
import ContactUsForm from "../../containers/ContactUsForm/ContactUsForm";

const HomePage = () => {
  useEffect(() => {
    const section = document.querySelectorAll("section");
    const lists = document.querySelectorAll(".nav-item-with-active");

    const activeLink = (li) => {
      lists?.forEach((item) => item.classList.remove("active"));
      li?.classList.add("active");
    };

    if (section && lists) {
      lists.forEach((item) =>
        item.addEventListener("click", function () {
          activeLink(this);
        })
      );

      window.onscroll = () => {
        section.forEach((sec) => {
          const top = window.scrollY;
          const offset = sec.offsetTop;
          const height = sec.offsetHeight;
          const id = sec.getAttribute("id");

          if (top >= offset - height / 3 && top < offset + height) {
            const target = document.querySelector(
              `[id='#${id}']`
            )?.parentElement;
            activeLink(target);
          }
        });
      };
    }

    return () => {
      lists?.forEach((item) =>
        item.removeEventListener("click", function () {
          activeLink(this);
        })
      );
    };
  }, []);

  return (
    <div>
      <main className="main-wrapper">
        <AlertBanner />
        <Banner />
        <About isDark={true} />
        <Customers />
        <Solution />
        <Features />
        {/* <ProductOverview /> */}
        <DemoCall />
        <Statistics />
        <ContactUsForm />
      </main>
    </div>
  );
};

export default HomePage;
