import React from "react";
import "./DemoCall.css";
import BrandButton from "../../components/BrandButton";

const DemoCall = () => {
  return (
    <div className="demo-call">
      <h5 className="demo-tagline">
        Introducing iSnagger, a versatile solution designed to revolutionize
        quality management.
      </h5>
      <div className="flex demo-button">
        <BrandButton>Book a 15 minute discovery call</BrandButton>
      </div>
    </div>
  );
};

export default DemoCall;
