import { MOBILE_WIDTH, SMALL_TABLET_WIDTH, TABLET_WIDTH } from "./constants";

export const isMobile = () =>
  window.matchMedia(`(max-width: ${MOBILE_WIDTH}px)`)?.matches;

export const isSmallTab = () =>
  window.matchMedia(`(max-width: ${SMALL_TABLET_WIDTH}px)`)?.matches;

export const isTablet = () =>
  window.matchMedia(`(max-width: ${TABLET_WIDTH}px)`)?.matches;

export const scrollToSection = (id) => {
  const currentSelectedSection = document.getElementById(id);

  if (currentSelectedSection) {
    window.scrollTo({
      top: currentSelectedSection.offsetTop,
      behavior: "smooth",
    });
  }
};
