export const landingPages = {
  home: {
    id: "home",
    label: "Home",
    path: "/",
  },
  aboutUs: {
    id: "about",
    label: "About Us",
    path: "/about-us",
  },
  solution: {
    id: "solution",
    label: "Solution",
    path: "/solution",
  },
  features: {
    id: "features",
    label: "Features",
    path: "/features",
  },
  // pricing: {
  //   id: "pricing",
  //   label: "Pricing",
  //   path: "/pricing",
  // },
  faq: {
    id: "faq",
    label: "FAQ",
    path: "/faq",
  },
};

export const formPages = {
  contactUs: {
    id: "contactUs",
    label: "Contact Us",
    path: "/contact-us",
  },
  bookADemo: {
    id: "bookADemo",
    label: "Book A Demo",
    path: "/book-a-demo",
  },
  freeTrial: {
    id: "freeTrial",
    label: "Free Trial",
    path: "/free-trial",
  },
  pricing: {
    id: "pricing",
    label: "Pricing",
    path: "/pricing",
  },
};
