import { AiOutlineIssuesClose } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { GoProject } from "react-icons/go";
import { VscIssues } from "react-icons/vsc";

export const statisticsData = [
  {
    id: "1",
    name: "Users Registered",
    icon: <FiUsers />,
    count: "81",
  },
  {
    id: "2",
    name: "Defects Logged",
    icon: <VscIssues />,
    count: "173089",
  },
  {
    id: "3",
    name: "Projects Managed",
    icon: <GoProject />,
    count: "17",
  },
  {
    id: "4",
    name: "Sqft Area Snagged",
    icon: <AiOutlineIssuesClose />,
    count: "3M+",
  },
];
