import { GrDocumentConfig } from "react-icons/gr";
import { FaMapMarkerAlt, FaUserLock, FaProjectDiagram } from "react-icons/fa";
import {
  BiSolidReport,
  BiSolidCustomize,
  BiSolidDevices,
} from "react-icons/bi";
import { MdNotificationsActive, MdOfflinePin } from "react-icons/md";
import { AiOutlineTeam } from "react-icons/ai";

export const featuresData = [
  {
    id: "1",
    icon: <MdNotificationsActive />,
    heading: "Real-Time Notifications",
    description:
      "Receive instant updates about project activities, tasks, and changes, keeping all stakeholders informed and enabling timely actions.",
  },
  {
    id: "2",
    icon: <BiSolidReport />,
    heading: "Advanced Dashboard and Analytics",
    description:
      "Leverage robust reporting tools to analyze data, monitor progress, and gain insights for effective decision-making and problem-solving.",
  },
  {
    id: "3",
    icon: <FaProjectDiagram />,
    heading: "Enabled with Escalation Matrix",
    description:
      "Ensure swift issue resolution by configuring an escalation matrix that directs tasks or problems to the appropriate personnel.",
  },
  {
    id: "4",
    icon: <MdOfflinePin />,
    heading: "Offline Data Sync",
    description:
      "Work seamlessly even in areas without internet connectivity. All data is automatically synchronized when reconnected to the network.",
  },
  {
    id: "5",
    icon: <FaMapMarkerAlt />,
    heading: "GPS-Enabled Location Tracking",
    description:
      "Track and record the exact location of activities or issues with GPS integration, enhancing accountability and efficiency.",
  },
  {
    id: "6",
    icon: <BiSolidCustomize />,
    heading: "Customized Business-Specific Rules",
    description:
      "Tailor workflows and processes to meet specific business needs by configuring rules and automations that align with your objectives.",
  },
  {
    id: "7",
    icon: <GrDocumentConfig />,
    heading: "Auto-Generated Customized Reports for Clients",
    description:
      "Automatically generate professional, client-specific reports with customizable formats, saving time and ensuring precise communication.",
  },
  {
    id: "8",
    icon: <FaUserLock />,
    heading: "User Permission and Access Control",
    description:
      "Protect sensitive data and manage user roles with robust access control settings, enabling secure collaboration and efficient task delegation.",
  },
  {
    id: "9",
    icon: <BiSolidDevices />,
    heading: "Cross-Platform Compatibility",
    description:
      "Enjoy a consistent experience across devices and platforms, enabling seamless access to your tools from anywhere, anytime.",
  },
  {
    id: "10",
    icon: <AiOutlineTeam />,
    heading: "Enhanced Team Collaboration",
    description:
      "Empower teams to work together efficiently with real-time communication, task assignment, and progress tracking features.",
  },
];
