import React, { useEffect, useState } from "react";
import Brand from "./components/Brand";
// import NavbarMenus from "./components/NavbarMenus";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import { scrollToSection } from "../../helpers/methods";
import { formPages, landingPages } from "../../pages";
import BrandButton from "../../components/BrandButton";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsScrolled(window.scrollY > 50);
    });
  }, []);

  const location = useLocation();

  const handleMenuOptionClick = (id) => {
    scrollToSection(id);

    if (showDrawer) {
      setShowDrawer(false);
    }
  };

  const toggleNavbarDrawer = () => {
    setShowDrawer((prevState) => !prevState);
  };

  return (
    <header
      className={`navbar navbar-expand-md bg-white sticky-top ${
        isScrolled ? "sticky" : ""
      }`}
    >
      <div className="container-inner d-flex">
        <Brand />
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleNavbarDrawer}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className={`collapse navbar-collapse ${showDrawer ? "show" : ""}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav text-nowrap">
            {Object.keys(landingPages)
              .slice(0, -1)
              .map((pageKey) => {
                const page = landingPages[pageKey];

                return (
                  <li
                    key={page.id}
                    className={`nav-item-with-active nav-item ${
                      location.pathname === page.path ? "active" : ""
                    }`}
                  >
                    <Link
                      className="text-decoration-none"
                      id={`#${page.id}`}
                      onClick={() => handleMenuOptionClick(page.id)}
                    >
                      {page.label}
                    </Link>
                  </li>
                );
              })}
            <li className="nav-item">
              <Link
                className="btn-link text-decoration-none"
                // to={formPages.bookADemo.path}
                onClick={() => handleMenuOptionClick(formPages.bookADemo.path)}
              >
                <BrandButton>Book a Demo</BrandButton>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
