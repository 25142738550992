import React from "react";
// import { useNavigate } from "react-router-dom";
// import { formPages } from "../../pages";
// import BrandButton from "../../components/BrandButton";
import "./Banner.css";
import { INFO_EMAIL } from "../../helpers/constants";

const Banner = () => {
  // const navigate = useNavigate();

  return (
    <section className="banner-section container section_fit" id="home">
      <div className="row justify-content-center align-items-center mx-0">
        <div className="col-md-6">
          <h2 className="banner-h2">
            Revolutionizing Inspection,
            <span className="banner-span"> Quality </span>& Safety Management
          </h2>
          <p className="banner-p">
            <span className="text-brand">iSnagger</span> – A versatile solution
            for seamless Quality Management.
          </p>
          <div className="banner-btn-div">
            <a className="" href={`mailto:${INFO_EMAIL.trim()}`}>
              <button className={`btn btn-primary rounded-pill`}>
                Book a Demo
              </button>
            </a>
            <a className="" href={`mailto:${INFO_EMAIL.trim()}`}>
              <button
                className="btn btn-outline-dark rounded-pill fw-bold"
                // onClick={() => navigate(`${formPages.freeTrial.path}`)}
              >
                Free 14 day trial
              </button>
            </a>
          </div>
        </div>
        <div className="col-md-6 banner-image-container">
          <img
            src="/assets/images/section-images/banner_section.png"
            alt="banner"
            className="banner-image"
          />
        </div>
      </div>
    </section>
  );
};

export default Banner;
